$spacing: 8px;
$borderRadius: 24px;
$borderColor: #9597a7;

.studyTopBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: $spacing * 2 $spacing * 2;
  background: rgb(53, 59, 99);
  background: linear-gradient(
    0deg,
    rgba(53, 59, 99, 1) 0%,
    rgba(19, 21, 34, 1) 100%
  );
  transform: translateY(-100%);
  transition: transform 0.3s ease-in;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 4px;
    background: rgb(11, 53, 72);
    background: linear-gradient(
      90deg,
      rgba(11, 53, 72, 1) 0%,
      rgba(254, 254, 254, 1) 50%,
      rgba(11, 53, 72, 1) 100%
    );
  }
  &.active {
    transform: translateY(0);
    transition: transform 0.3s ease-in;
  }
  .studyTopBar-leftAction,
  .studyTopBar-rightAction {
    display: block;
    width: 100%;
    max-width: 180px;
  }
  .studyTopBar-rightAction {
    text-align: right;
  }
  .studyTopBar-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing $spacing * 1.5;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background: transparent;
    cursor: pointer;
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
      border-color: #fff;
    }
    img {
      display: block;
      width: 85px;
      height: 50px;
      object-fit: scale-down;
      margin-right: $spacing;
    }
  }
}
