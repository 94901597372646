$spacing: 8px;
$borderRadius: 24px;

.studyLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: inherit;
  .studyLayout-poster {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
  .studyLayout-video {
    display: block;
    width: 100%;
    height: auto;
    //&::-webkit-media-controls-panel {
    //}
    //&::-webkit-media-controls-play-button {
    //  display: none;
    //}
    //&::-webkit-media-controls-volume-slider-container {
    //  display: none;
    //}
    //&::-webkit-media-controls-volume-slider {
    //  display: none;
    //}
    //&::-webkit-media-controls-mute-button {
    //  display: none;
    //}
    //&::-webkit-media-controls-timeline {
    //}
    //&::-webkit-media-controls-current-time-display {
    //  display: none;
    //}
    //&::-webkit-full-page-media::-webkit-media-controls-panel {
    //  display: none;
    //}
    //&::-webkit-media-controls-timeline-container {
    //  display: none;
    //}
    //&::-webkit-media-controls-time-remaining-display {
    //  display: none;
    //}
    //&::-webkit-media-controls-seek-back-button {
    //  display: none;
    //}
    //&::-webkit-media-controls-seek-forward-button {
    //  display: none;
    //}
    //&::-webkit-media-controls-fullscreen-button {
    //  display: none;
    //}
    //&::-webkit-media-controls-rewind-button {
    //  display: none;
    //}
    //&::-webkit-media-controls-return-to-realtime-button {
    //  display: none;
    //}
    //&::-webkit-media-controls-toggle-closed-captions-button {
    //  display: none;
    //}
  }
}
