$spacing: 8px;
$borderRadius: 24px;

.chooseLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: inherit;
  .selectionList {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1524px;
    margin: 0 auto auto;
    overflow-y: auto;
    @media screen and (max-width: 1600px) {
      max-width: 1024px;
    }
    .selectionList-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      max-width: calc((1524px - ((8px * 1.5) * 8)) / 4);
      padding: $spacing * 2 $spacing * 2;
      margin: 0 $spacing * 1.5 $spacing * 3;
      background: rgb(11, 53, 72);
      background: linear-gradient(
        90deg,
        rgba(11, 53, 72, 1) 0%,
        rgba(254, 254, 254, 1) 50%,
        rgba(11, 53, 72, 1) 100%
      );
      opacity: 1;
      transition: opacity 0.3s ease-in;
      border: 0 solid transparent;
      border-radius: $borderRadius;
      text-decoration: none;
      cursor: pointer;
      z-index: 0;
      //&:nth-child(3) {
      //  max-width: calc(((1524px - ((8px * 1.5) * 8)) / 4) + 1px);
      //}
      @media screen and (max-width: 1600px) {
        max-width: calc((1024px - ((8px * 1.5) * 8)) / 4);
      }
      &:before {
        content: "";
        position: absolute;
        top: $spacing * 0.5;
        left: $spacing * 0.5;
        right: $spacing * 0.5;
        bottom: $spacing * 0.5;
        border: inherit;
        border-radius: inherit;
        background: rgb(19, 21, 34);
        background: linear-gradient(
          90deg,
          rgba(19, 21, 34, 1) 0%,
          rgba(53, 59, 99, 1) 50%,
          rgba(19, 21, 34, 1) 100%
        );
        z-index: -1;
      }
      &:hover {
        &:before {
          opacity: 0.5;
          background: rgb(2, 6, 29);
          background: linear-gradient(
            90deg,
            rgba(2, 6, 29, 1) 0%,
            rgba(54, 64, 121, 1) 50%,
            rgba(2, 6, 29, 1) 100%
          );
        }
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}
