$spacing: 8px;
$borderRadius: 24px;

.mainPage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: url("./assets/bg.png");
  background-size: cover;
  background-position: center center;
  z-index: 0;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.85;
    background: rgb(13, 15, 24);
    background: linear-gradient(
      0deg,
      rgba(13, 15, 24, 1) 0%,
      rgba(61, 70, 122, 1) 48%,
      rgba(27, 29, 42, 1) 100%
    );
    z-index: -1;
  }
  .mainPage-bgVideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
  }
  .mainPage-close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 100px;
  }
  .mainPage-centerWrapper {
    display: block;
    width: 100%;
    min-height: 80vh;
    padding: $spacing * 2;
    .mainPage-headingImg {
      display: block;
      width: 100%;
      height: auto;
      max-width: 290px;
      margin: 0 auto $spacing * 3;
      @media screen and (max-width: 780px) {
        max-width: 160px;
      }
    }
    .mainPage-logo {
      display: block;
      width: 100%;
      height: auto;
      max-width: 590px;
      margin: 0 auto;
    }
    .mainPage-divideGradient {
      display: block;
      width: 100%;
      max-width: 1366px;
      height: auto;
      margin: 0 auto;
    }
    .btnStart {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background: rgb(22, 77, 103);
      background: linear-gradient(
        90deg,
        rgba(22, 77, 103, 1) 0%,
        rgba(114, 211, 231, 1) 50%,
        rgba(22, 77, 103, 1) 100%
      );
      border: 0;
      border-radius: $borderRadius;
      color: #fff;
      padding: $spacing * 1 $spacing * 1;
      margin: $spacing * 6 auto 0;
      width: 100%;
      max-width: 320px;
      min-height: $spacing * 8;
      font-size: 24px;
      text-align: center;
      text-decoration: none;
      z-index: 0;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        top: $spacing * 0.8;
        left: $spacing * 0.8;
        right: $spacing * 0.8;
        bottom: $spacing * 0.8;
        border-radius: inherit;
        opacity: 0.85;
        background: rgb(38, 43, 74);
        background: linear-gradient(
          90deg,
          rgba(38, 43, 74, 1) 0%,
          rgba(82, 93, 157, 1) 50%,
          rgba(38, 43, 74, 1) 100%
        );
        transition: all 0.3s ease-in;
        z-index: -1;
      }
      &:hover {
        &:before {
          opacity: 0.5;
          background: rgb(2, 6, 29);
          background: linear-gradient(
            90deg,
            rgba(2, 6, 29, 1) 0%,
            rgba(54, 64, 121, 1) 50%,
            rgba(2, 6, 29, 1) 100%
          );
        }
      }
    }
  }
}
