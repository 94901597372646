$spacing: 8px;
$borderRadius: 24px;
$borderColor: #9597a7;

.studyBottomBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: $spacing * 2 $spacing * 2;
  background: rgb(53, 59, 99);
  background: linear-gradient(
    0deg,
    rgba(53, 59, 99, 1) 0%,
    rgba(19, 21, 34, 1) 100%
  );
  transform: translateY(100%);
  transition: transform 0.3s ease-in;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    height: 4px;
    background: rgb(11, 53, 72);
    background: linear-gradient(
      90deg,
      rgba(11, 53, 72, 1) 0%,
      rgba(254, 254, 254, 1) 50%,
      rgba(11, 53, 72, 1) 100%
    );
  }
  &.active {
    transform: translateY(0);
    transition: transform 0.3s ease-in;
  }
  .proposeList {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    overflow-y: auto;
    .proposeList-item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 370px;
      border: 1px solid $borderColor;
      border-radius: $borderRadius;
      background: transparent;
      padding: $spacing $spacing * 1.5;
      margin: $spacing $spacing;
      text-decoration: none;
      transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      &:hover {
        border-color: #fff;
      }
      .proposeList-item-preview {
        display: block;
        width: 155px;
        height: 138px;
        object-fit: scale-down;
        margin-right: $spacing * 2;
        border: 0 solid transparent;
        border-radius: $borderRadius;
      }
    }
  }
}
