$spacing: 8px;

.appTemplate {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("./assets/bg.png");
  background-size: cover;
  background-position: center center;
  overflow-y: hidden;
  .appTemplate-leftBar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    max-width: 80px;
    padding: 0 0 0 $spacing * 2;
    z-index: 1;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .appTemplate-content {
    display: block;
    position: relative;
    width: 100%;
    height: inherit;
  }
}
